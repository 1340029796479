import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import ToggleColorMode from './ToggleColorMode';
import { useNavigate} from "react-router-dom";
import logo from '../photos/Logo.png';


const logoStyle = {
  width: '140px',
  height: 'auto',
  cursor: 'pointer',
};

function AppAppBar({ mode, toggleColorMode }) {
  const [open, setOpen] = React.useState(false);

  let navigate = useNavigate();
  const url = window.location.href;
  const params = url.split("/"); 
  const urlColor = params[3];

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
      setOpen(false);
    }

  };

  const handleClick = () => {
    navigate({
      pathname:'/',
      state: { targetId: "footer" },
    });
  };

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
          mt: 2,
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexShrink: 0,
              //borderRadius: '999px', ASK AFZAL IF HE WANTS ROUND
              bgcolor:
                theme.palette.mode === 'light'
                  ? '#522f29'
                  : 'rgba(0, 0, 0, 0.4)',
              backdropFilter: 'blur(24px)',
              maxHeight: 40,
              border: '1px solid',
              borderColor: 'divider',
              boxShadow:
                theme.palette.mode === 'light'
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                ml: '-18px',
                px: 0,
              }}
            >
              <img
                src={logo}
                style={logoStyle}
                alt="PUT LOGO HERE"
              />
              <Box sx={{ display: { xs: 'none', md: 'flex' }, gap:'30px', marginLeft:'2em' }}>
                <MenuItem
                  onClick={() => {navigate({pathname:'/'});}}
                  sx={{ py: '6px', px: '32px' }}
                >
                  <Typography variant="body2" fontSize={'16px'} color={(urlColor === '' ? 'black':'white')} backgroundColor={(urlColor === '' ? 'orange':'')} border={'none'} borderRadius={'10px'} width={'80px'}>
                    Home
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {navigate({pathname:'/products/'});}}
                  sx={{ py: '6px', px: '32px' }}
                >
                  <Typography variant="body2" color={(urlColor === 'products' ? 'black':'white')} fontSize={'16px'} backgroundColor={(urlColor === 'products' ? 'orange':'')} border={'none'} borderRadius={'10px'} width={'80px'}>
                    Products
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => scrollToSection('highlights')}
                  sx={{ py: '6px', px: '32px' }}
                >
                  <Typography variant="body2" color={(urlColor === 'recipes' ? 'black':'white')} fontSize={'16px'}>
                    Recipes
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => scrollToSection('footer')} sx={{ py: '6px', px: '32px' }}
                >
                  <Typography variant="body2" color="text.new" fontSize={'16px'}>
                    About us
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => { handleClick();
                    scrollToSection('faq')}}
                  sx={{ py: '6px', px: '32px' }}
                >
                  <Typography variant="body2" color="text.new" fontSize={'16px'}>
                    FAQ
                  </Typography>
                </MenuItem>
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                gap: 0.5,
                alignItems: 'center',
              }}
            >
            </Box>
            <Box sx={{ display: { sm: '', md: 'none' } }}>
              <Button
                variant="text"
                color="primary"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ minWidth: '30px', p: '4px' }}
              >
                <MenuIcon />
              </Button>
              <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Box
                  sx={{
                    minWidth: '60dvw',
                    p: 2,
                    backgroundColor: '#e7c68a',
                    flexGrow: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'end',
                      flexGrow: 1,
                    }}
                  >
                    <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
                  </Box>
                  <MenuItem onClick={() => {navigate({pathname:'/'});}}>
                    <Typography variant="body2" fontSize={'16px'} textAlign={'center'} color={'black'} backgroundColor={(urlColor === '' ? 'orange':'')} border={'none'} borderRadius={'10px'} width={'80px'}>
                      Home
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={() => {navigate({pathname:'/products/'});}}>
                    <Typography variant="body2" fontSize={'16px'} textAlign={'center'} color={'black'} backgroundColor={(urlColor === 'products' ? 'orange':'')} border={'none'} borderRadius={'10px'} width={'80px'}>
                      Products
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={() => {navigate({pathname:'/'});}}>
                    <Typography variant="body2" fontSize={'16px'} textAlign={'center'} color={'black'} border={'none'} borderRadius={'10px'} width={'80px'}>
                      Recipes
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={() => {navigate({pathname:'/'});}}>
                    <Typography variant="body2" fontSize={'16px'} textAlign={'center'} color={'black'} border={'none'} borderRadius={'10px'} width={'80px'}>
                      About Us
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={() => {navigate({pathname:'/'});}}>
                    <Typography variant="body2" fontSize={'16px'} textAlign={'center'} color={'black'} border={'none'} borderRadius={'10px'} width={'80px'}>
                      FAQ
                    </Typography>
                  </MenuItem>
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default AppAppBar;