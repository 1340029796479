import main from './Website Front.jpg';
import slide1 from './SlideshowImage1.jpg';
import slide2 from './SlideshowImage2.jpg';
import slide3 from './SlideshowImage3.jpg';
import slide4 from './SlideshowImage4.jpg';
import slide5 from './SlideshowImage5.jpg';


export const slideShowImages = {
"main":{src:main},
"slide1":{src:slide1},
"slide2":{src:slide2},
"slide3":{src:slide3},
"slide4":{src:slide4},
"slide5":{src:slide5},
}