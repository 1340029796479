import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';

const userTestimonials = [
  {
    avatar: <Avatar alt="Ayesha Dee" src="/static/images/avatar/1.jpg" />,
    name: 'Ayesha Dee',
    occupation: 'Senior Engineer',
    testimonial:
      "Great, fresh, flavorful spices!   I've probably bought 10 different mixes from this company and they have always been fresh, flavorful & aromatic and the prices are competitive. When I need more, this is the place I'll purchase from.",
  },
  {
    avatar: <Avatar alt="Deep Patel" src="/static/images/avatar/2.jpg" />,
    name: 'Deep Patel',
    occupation: 'Lead Product Designer',
    testimonial:
      "I absolutely love your products they are so great!!! They have such great fresh flavor and the smell when you open them is amazing.I will be a repeat customer many times over!",
  },
  {
    avatar: <Avatar alt="Nimesh Sarkar" src="/static/images/avatar/3.jpg" />,
    name: 'Nimesh Sarkar',
    occupation: 'CTO',
    testimonial:
      'Lovely products, definitely recommend!! ',
  },
  {
    avatar: <Avatar alt="Hetvi Patel" src="/static/images/avatar/4.jpg" />,
    name: 'Hetvi Patel',
    occupation: 'Senior Engineer',
    testimonial:
      "As a vegan, I love the varity of vegan products Zara Gold provides :) ",
  },
  {
    avatar: <Avatar alt="Humaira Shaikh" src="/static/images/avatar/5.jpg" />,
    name: 'Humaira Shaikh',
    occupation: 'Product Designer',
    testimonial:
      "I've tried other similar products, but this one stands out alot in terms of quality and taste. My family uses these products everyday! Thank you Zaara Gold :)",
  },
  {
    avatar: <Avatar alt="Aalia Kara" src="/static/images/avatar/6.jpg" />,
    name: 'Aalia Kara',
    occupation: 'CDO',
    testimonial:
      "I LOVE everything about Zaara Gold :) Love all the varities of products!! ",
  },
];

const whiteLogos = [
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628e8573c43893fe0ace_Sydney-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d520d0517ae8e8ddf13_Bern-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f46794c159024c1af6d44_Montreal-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e891fa22f89efd7477a_TerraLight.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a09d1f6337b1dfed14ab_colorado-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5caa77bf7d69fb78792e_Ankara-white.svg',
];

const darkLogos = [
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628889c3bdf1129952dc_Sydney-black.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d4d8b829a89976a419c_Bern-black.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f467502f091ccb929529d_Montreal-black.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e911fa22f2203d7514c_TerraDark.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a0990f3717787fd49245_colorado-black.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5ca4e548b0deb1041c33_Ankara-black.svg',
];

const logoStyle = {
  width: '64px',
  opacity: 0.3,
};

export default function Testimonials() {
  const theme = useTheme();
  const logos = theme.palette.mode === 'light' ? darkLogos : whiteLogos;

  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 2, sm: 3 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          Testimonials
        </Typography>
        <Typography variant="body1" color="text.secondary">
          See what our customers love about our products. Discover how we excel in
          efficiency, durability, and satisfaction. Join us for quality, innovation,
          and reliable support.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {userTestimonials.map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
                p: 1,
                backgroundColor:'#faf2e7'
              }}
            >
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  pr: 2,
                }}
              >
                <Typography>
                  {testimonial.name}
                </Typography>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}